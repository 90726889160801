import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';

export default class BombFeatureState extends BaseState {
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_DATA_UPDATED, this.useBomb, this);
    GlobalDispatcher.add(eEventTypes.EET_BOMB_ACTION_END, this.complete, this);
  }

  start() {
    super.start();
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    GlobalDispatcher.dispatch(eEventTypes.EET_SEND_BOMB_REQUEST);
  }

  useBomb({ params }) {
    if (params === 'use_bomb') {
      GlobalDispatcher.dispatch(eEventTypes.EET_USE_BOMB);
    }
  }
}
