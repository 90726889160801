import BaseButton from 'Engine/base/ui/BaseButton';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../enums/eSounds';

export default class BaseGameButton extends BaseButton {
  constructor(...arg) {
    super(...arg);
    this.on('pointertap', () => SoundManager.play(eSounds.EST_BUTTON_CLICK.name))
  }
}
