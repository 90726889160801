import EntryPoint from 'Engine/EntryPoint';
import { webaudio } from '@pixi/sound';
import { settings } from '@pixi/core';

function convertToSprite() {
  const localBounds = this.getLocalBounds(null, true);
  const tempContainer = new PIXI.Container();
  const savedParent = this.parent;
  const index = this.parent && this.parent.children.indexOf(this);
  const savedTransform = new PIXI.Matrix();
  this.transform.localTransform.copyTo(savedTransform);
  this.transform.localTransform.copyFrom(new PIXI.Matrix());

  tempContainer.addChild(this);
  const bitmapTexture = EntryPoint.Game.app.renderer.generateTexture(tempContainer, {
    region: localBounds,
    multisample: PIXI.MSAA_QUALITY.NONE
  });
  tempContainer.removeChildren();
  if (savedParent) savedParent.addChildAt(this, index);
  this.transform.localTransform.copyFrom(savedTransform);

  bitmapTexture.defaultAnchor = { x: -localBounds.x / localBounds.width, y: -localBounds.y / localBounds.height };
  return new PIXI.Sprite(bitmapTexture);
}

PIXI.DisplayObject.prototype.convertToSprite = convertToSprite;

PIXI.Assets._originLoad = PIXI.Assets.load;
const loadPromises = {};
PIXI.Assets.load = async function (urls, onProgres) {
  if (!loadPromises[urls] || onProgres) {
    const load = PIXI.Assets._originLoad(urls, onProgres).catch((e) => {
      console.error(e);
      window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    });
    loadPromises[urls] = load;
    return load;
  } else {
    return new Promise(async resolve => {
      loadPromises[urls]
        .then(resolve)
        .catch((e) => {
        })
    })
  }
}

webaudio.WebAudioMedia._originLoadUrl = webaudio.WebAudioMedia.prototype._loadUrl;

webaudio.WebAudioMedia.prototype._loadUrl = async function (callback) {
  const url = this.parent.url;
  try {
    const response = await settings.ADAPTER.fetch(url);
    this._decode(await response.arrayBuffer(), callback);
  } catch (e) {
    callback(e)
  }

}

