import flashlib from '../../../assets/exported/gameMobile/FlashLib.json';

import starTexture from '../../../assets/images/star_particle.png';
import dotTexture from '../../../assets/images/dot.png';

import UbuntuBold from '../../../assets/fonts/UBUNTU-B.TTF';
import UbuntuRegular from '../../../assets/fonts/UBUNTU-R.TTF';
import eAnimationTypes from '../enums/eAnimationTypes';
import Config from '../configs/scaleConfig';
import { getAssetsScale } from 'Engine/utils/getAssetsScale';
import { soundsPreloaderConfig } from '../enums/eSounds';

const scale = getAssetsScale({
  availableScales: ['0.7', '0.75', '0.8', '0.9'],
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
})
const assets = `flashlib/gameMobile/AssetsCombined@${scale}x.json`;

const config = {
  assets: [
    { name: 'GameAssets', path: assets, extension: 'json' },
    { name: 'GameFlashLib', path: flashlib, extension: 'json' },
    { name: eAnimationTypes.EAT_SYMBOLS, path: 'animations/symbols_dew/crystalcrush_symbols_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BACKGROUND, path: 'animations/FB_bg_animation_pro_dew/bg_animation.json', extension: 'json' },
    { name: eAnimationTypes.EAT_EXPRESSIONS, path: 'animations/cc_expressions_animation_DEW/expressions_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BOMB, path: 'animations/cc_tnt_DEW/cc_tnt.json', extension: 'json' },
    { name: eAnimationTypes.EAT_COLOR_BOMB_RAY, path: 'animations/color_bomb_ray/skeleton.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PROGRESS, path: 'animations/cc_progress_start_screen_pro_DEW/cc_progress_start_screen_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PURCHASE, path: 'animations/cc_Bonus purchase_dew/purchase_bonuses.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PURCHASE_BUTTON, path: 'animations/bonus_purchase_button_pro_DEW/button_bones_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_COINS, path: 'animations/coins/skeleton.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BIG_WINS, path: 'animations/big_wins/DWB.json', extension: 'json' },
    { name: eAnimationTypes.EAT_CHARACTERS, path: 'animations/pers_pro_DEW/skeleton.json', extension: 'json' },
    { name: 'cc_font', path: 'fonts/bitmap/cc_font.xml', extension: 'xml' },
    { name: 'starsParticle', path: starTexture, extension: 'png' },
    { name: 'dotParticle', path: dotTexture, extension: 'png' },
  ],
  delayedAssets: [
    { name: eAnimationTypes.EAT_PURCHASE, path: 'animations/cc_Bonus purchase_dew/purchase_bonuses.json', extension: 'json' },
  ],
  fonts: [
    {
      name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    }
  ],
  sounds: soundsPreloaderConfig,
};


export default config;
