import FlashLib from "flashlib_onlyplay";
import ControllerStartScreen from './controllers/modals/ControllerStartScreen';
import ControllerFreeSpinsWindow from './controllers/modals/ControllerFreeSpinsWindow';
import ControllerMain from './controllers/scenes/ControllerMain';
import ControllerUi from './controllers/ui/ControllerUi';
import ControllerField from './controllers/field/ControllerField';
import ControllerSymbol from './controllers/symbols/ControllerSymbol';
import ControllerProgress from './controllers/progress/ControllerProgress';
import ControllerBackground from './controllers/background/ControllerBackground';
import ControllerExpressions from './controllers/expressions/ControllerExpressions';
import ControllerBomb from './controllers/bomb/ControllerBomb';
import ControllerBombTimer from './controllers/bomb/ControllerBombTimer';
import ControllerTip from './controllers/tip/ControllerTip';
import ControllerOverlay from './controllers/common/overlay/ControllerOverlay';
import ControllerPaytableContainer from './controllers/paytable/ControllerPaytableContainer';
import ControllerPaytableContent from './controllers/paytable/ControllerPaytableContent';
import ControllerPaytableControls from './controllers/paytable/ControllerPaytableControls';
import ControllerBonusPurchase from './controllers/bonusPurchase/ControllerBonusPurchase';
import ControllerBonusPurchaseCard from './controllers/bonusPurchase/ControllerBonusPurchaseCard';
import ControllerConfirm from './controllers/bonusPurchase/ControllerConfirm';
import ControllerBigWinWindow from './controllers/bigWin/ControllerBigWinWindow';
import ControllerSettings from 'Engine/containers/setting/BaseSettingsContainer';

import BaseGameButton from './controllers/ui/BaseGameButton';
import BaseCheckBox from "Engine/base/ui/BaseCheckBox";
import ButtonAnimated from "./controllers/common/buttonAnimated/ButtonAnimated";
import BaseBetSelector from 'Engine/containers/ui/BaseCoinValueContainer';
import ControllerColorBomb from './controllers/progress/ControllerColorBomb';
import ControllerBombTip from './controllers/tip/ControllerBombTip';

FlashLib.registerClass('ControllerStartScreen', ControllerStartScreen);
FlashLib.registerClass('ControllerFreeSpinsWindow', ControllerFreeSpinsWindow);
FlashLib.registerClass('ControllerMain', ControllerMain);
FlashLib.registerClass('ControllerUi', ControllerUi);
FlashLib.registerClass('ControllerField', ControllerField);
FlashLib.registerClass('ControllerSymbol', ControllerSymbol);
FlashLib.registerClass('ControllerProgress', ControllerProgress);
FlashLib.registerClass('ControllerBackground', ControllerBackground);
FlashLib.registerClass('ControllerExpressions', ControllerExpressions);
FlashLib.registerClass('ControllerBomb', ControllerBomb);
FlashLib.registerClass('ControllerBombTimer', ControllerBombTimer);
FlashLib.registerClass('ControllerTip', ControllerTip);
FlashLib.registerClass('ControllerBombTip', ControllerBombTip);
FlashLib.registerClass('ControllerOverlay', ControllerOverlay);
FlashLib.registerClass('ControllerPaytableContainer', ControllerPaytableContainer);
FlashLib.registerClass('ControllerPaytableContent', ControllerPaytableContent);
FlashLib.registerClass('ControllerPaytableControls', ControllerPaytableControls);
FlashLib.registerClass('ControllerBonusPurchase', ControllerBonusPurchase);
FlashLib.registerClass('ControllerBonusPurchaseCard', ControllerBonusPurchaseCard);
FlashLib.registerClass('ControllerConfirm', ControllerConfirm);
FlashLib.registerClass('ControllerBigWinWindow', ControllerBigWinWindow);
FlashLib.registerClass('ControllerSettings', ControllerSettings);

FlashLib.registerClass('BaseButton', BaseGameButton);
FlashLib.registerClass('BaseCheckBox', BaseCheckBox);
FlashLib.registerClass('ButtonAnimated', ButtonAnimated);

FlashLib.registerClass('BaseBetSelector', BaseBetSelector);
FlashLib.registerClass('ControllerColorBomb', ControllerColorBomb);
