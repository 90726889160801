import LocalizeTextField from 'Engine/base/localization/LocalizeTextField';

export default class ControllerTextField extends LocalizeTextField {
  constructor(...args) {
    super(...args);

    this._positionArguments = [];
    this._resolutionListener = this.onResize.bind(this);
  }

  onResize({ scale }) {
    let resolution =  window.OPWrapperService.ScaleManager.data?.app.renderer.options.resolution || 2;
    this.resolution = scale < 1 ? 1 : resolution;
  }

  fitSize() {
    super.fitSize(true, true);
  }

  correctPosition = (...args) => {
    if (args.length) {
      this._positionArguments = args;
    }

    super.correctPosition(...this._positionArguments);
  };

  _addResolutionListener() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._resolutionListener
    );
  }

  _removeResolutionListener() {
    window.OPWrapperService.eventManager.remove(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._resolutionListener
    );
  }

  set adaptiveResolution(value) {
    if (value) {
      this._addResolutionListener();
    } else {
      this._removeResolutionListener();
    }
  }
}
