import * as PIXI from 'pixi.js';

window.PIXI = PIXI;
require('pixi-spine');
import '../css/index.css';
import 'Engine/utils/number';
import 'Engine/utils/flashlibExtantion';
import '../../assets/scss/styles.scss';
import FlashLib from 'flashlib_onlyplay';
import './imports';
import BaseGame from 'Engine/BaseGame';
import StatesManager from 'Engine/base/states/StatesManager';
import GameModel from './models/GameModel';
import { StatesConfig } from './states/StatesConfig'
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import BaseGameSettings from 'Engine/base/settings/BaseGameSettings';
import AutoPlaySettings from 'Engine/base/settings/AutoPlaySettings';
import SoundManager from 'Engine/soundManager/SoundManager';
import OPWrapperService from 'WrapperService/index';
import en from '../../assets/localizations/en';
import enSocial from '../../assets/localizations/en-social';

import background from '../../assets/images/preloader_background.webp';
import { eSounds } from './enums/eSounds';
import eEventTypes from './enums/eEventTypes';
import ControllerTextField from './textField/ControllerTextField';
import Config from './configs/scaleConfig';
import 'Preloader/preloader';
import preloaderConfig from './configs/preloaderConfig';
import eLibraryItems from './enums/eLibraryItems';
import { MotionPathPlugin } from './utils/gsap/MotionPathPlugin.min';
import { gsap } from 'gsap';

gsap.registerPlugin(MotionPathPlugin);

export default new (class Game extends BaseGame {

  constructor(preloaderConfig) {
    EntryPoint.compiled = true;
    super(preloaderConfig);

    EntryPoint.Game = this;
  }

  initStartClasses() {
    this.initWrapperService();
    GameModel.init();
    (new BaseGameSettings).init();
    (new AutoPlaySettings).init();
    StatesManager.setStatesConfig(StatesConfig);

    FlashLib.TextField = ControllerTextField;

    GlobalDispatcher.add(eEventTypes.EET_SPIN_CLICK, StatesManager.goToNextState, StatesManager);
  }

  initWrapperService() {
    this.wrapperConfig = {
      onButtonClick: this.onWrapperButtonClick.bind(this),
      bundle: 'crystalcascade',
      localizationBundle: 'juicycrush',
      localizations: { en, 'en-social': enSocial },
      lang: 'en',
      fallbackLang: 'en'
    };

    new OPWrapperService(this.wrapperConfig);
  }

  onWrapperButtonClick() {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name, eSounds.EST_BUTTON_CLICK.volume, false, eSounds.EST_BUTTON_CLICK.group);
  }

  onLoadingComplete() {
    this.constructGame();
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config);
  }

  initStartParams() {
    this.gameSize = {
      desktop: {
        width: 2340,
        height: 1080,
      },
      mobileLandscape: {
        width: 2340,
        height: 1080,
      },
      mobilePortrait: {
        width: 1080,
        height: 2340,
      },
    };
    this.scaleData = {
      app: this.app,
      gameSize: this.gameSize
    };
  }

  createPreloader() {
    super.createPreloader();
    this.preloaderView.style.background = `url(${background}) no-repeat center`;
    this.preloaderView.style.backgroundSize = `cover`;
  }

  constructGame() {
    EntryPoint.configData = PIXI.Assets.get('gameConfig');
    this.app.stop();
    this.main = FlashLib.createItemFromLibrary(eLibraryItems.ELI_MAIN, 'GameFlashLib');
    this.app.stage.addChildAt(this.main, 0);
    this.main.init();
    this.app.renderer.prepare.upload(this.main).then(() => {
      this.app.start();
      GlobalDispatcher.dispatch('game:gameStarted');
      this.hidePreloader();
      this.linkToPixi();
    });
  }
})(preloaderConfig);
