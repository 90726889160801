export default class WinAmountAnimated extends PIXI.utils.EventEmitter {
  constructor() {
    super();
    this._complete = false;
    this.onStop = () => {
    };
  }

  animate(startValue = 0, endValue, decimals = 2, duration, element, postfix) {
    this._complete = false;
    this.label = element;
    this.postfix = postfix;
    this.startValue = startValue;
    this.endValue = endValue;
    this.decimals = decimals;
    this.duration = duration;
    this.startTime = Date.now();
    requestAnimationFrame(this.animateValueChanged.bind(this));
  }

  stop() {
    this._complete = true;
    if (this.label) this.label.text = '';
    this.onStop();
  };

  complete() {
    this._complete = true;
    this.emit('complete');
  }

  animateValueChanged() {
    if (this._complete) return;
    const time = Date.now() - this.startTime;

    if (time >= this.duration) {
      this.label.text = this.postfix ? this.endValue.toFixed(this.decimals) + ' ' + this.postfix : this.endValue.toFixed(this.decimals);
      this.complete();
      return;
    }

    const progress = time / this.duration;
    const amount = (this.endValue - this.startValue) * progress + this.startValue;
    this.label.text = this.postfix ? amount.toFixed(this.decimals) + ' ' + this.postfix : amount.toFixed(this.decimals);

    requestAnimationFrame(this.animateValueChanged.bind(this));
  };
}
