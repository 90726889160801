import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';

export default class FreeSpinsOutState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_WINDOW_SHOWED, this.complete.bind(this));
  }

  complete() {
    if (this.active) {
      super.complete();
    }
  }

  onEnd() {
    super.onEnd();
    EntryPoint.GameModel.isFreeSpinsInActiveState = false;
    window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_REGULAR);
  }
}
