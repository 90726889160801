export const eSounds = {
  EST_BUTTON_CLICK: {
    name: 'buttonClick',
    volume: 1,
    group: 'default'
  },
  EST_SFX: {
    name: 'SFX',
    volume: 0.5,
    group: 'music'
  },
  EST_SYMBOL_STOP: {
    name: 'symbolStop',
    volume: 0.4,
    group: 'default'
  },
  EST_BIG_WIN_BACKGROUND: {
    name: 'bigWinBg',
    volume: 0.5,
    group: 'winning'
  },
  EST_BIG_WIN: {
    name: 'bigWin',
    volume: 0.6,
    group: 'winning'
  },
  EST_MEGA_WIN: {
    name: 'megaWin',
    volume: 0.6,
    group: 'winning'
  },
  EST_EPIC_WIN: {
    name: 'epicWin',
    volume: 0.6,
    group: 'winning'
  },
  EST_COLOR_BOMB_RAY: {
    name: 'colorBombRaySound',
    volume: 0.6,
    group: 'music'
  },
  EST_COLOR_BOMB_COLLAPSE: {
    name: 'colorBombCollapse',
    volume: 1,
    group: 'music'
  },
  EST_FIELD_BOMB: {
    name: 'fieldBomb',
    volume: 1,
    group: 'music'
  },
  EST_WIN_FREESPINS: {
    name: 'winFreespins',
    volume: 0.6,
    group: 'winning'
  },
  EST_FREESPINS_TOTAL_WIN: {
    name: 'freespinsTotalWin',
    volume: 0.6,
    group: 'winning'
  },
  EST_INCREASE_PROGRESS: {
    name: 'increaseProgress',
    volume: 0.4,
    group: 'music'
  },
  EST_SIMPLE_SYMBOL_DESTROY_5: {
    name: 'simpleSymbolDestroy_5',
    volume: 1,
    group: 'default'
  },
  EST_SIMPLE_SYMBOL_DESTROY_6: {
    name: 'simpleSymbolDestroy_6',
    volume: 1,
    group: 'default'
  },
  EST_SIMPLE_SYMBOL_DESTROY_7: {
    name: 'simpleSymbolDestroy_7',
    volume: 1,
    group: 'default'
  },
  EST_SIMPLE_SYMBOL_DESTROY_8: {
    name: 'simpleSymbolDestroy_8',
    volume: 1,
    group: 'default'
  },
};

export const soundsPreloaderConfig = Object.fromEntries(Object.keys(eSounds).map(key => [key, eSounds[key].name]));
