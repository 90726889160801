import SoundManager from 'Engine/soundManager/SoundManager';
import uiConfig from '../ui/config';
import UiAdapter from './UiAdapterExtended';
import { eSounds } from '../../enums/eSounds';

const initWrapperUi = () => {
  SoundManager.play(eSounds.EST_SFX.name, eSounds.EST_SFX.volume, true, eSounds.EST_SFX.group);
  window.OPWrapperService.freeBetsController.show();
  const ControllerUi = window.OPWrapperService.initUi(uiConfig);
  new UiAdapter(ControllerUi).init({
    bonusLabelText: 'FREESPINS',
    clickSound: { soundName: eSounds.EST_BUTTON_CLICK.name, volume: 1 }
  });
}

export {
  initWrapperUi,
}
