import FlashLib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import Animation from 'Engine/base/animation/Animation';
import ParticleSystem from 'Engine/particles/particleSystem';
import WinAmountAnimated from '../winAmountAnimated/winAmountAnimated';
import particlesConfig from 'Engine/particles/particleConfig';
import { gsap } from 'gsap';
import eAnimationTypes from '../../enums/eAnimationTypes';
import gameConfig from '../../configs/gameConfig';
import { eSounds } from '../../enums/eSounds';
import eEventTypes from '../../enums/eEventTypes';
import { violetStrokeStyle, yellowStyle } from '../../textStyles/styles';
import StatesManager from 'Engine/base/states/StatesManager';

export default class ControllerBigWinWindow extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.coefs = [
      { animationName: 'big_win_', coef: gameConfig.BIG_WIN_COEFS[0], frame: 1, sound: eSounds.EST_BIG_WIN.name },
      { animationName: 'mega_win_', coef: gameConfig.BIG_WIN_COEFS[1], frame: 2, sound: eSounds.EST_MEGA_WIN.name },
      { animationName: 'epic_win_', coef: gameConfig.BIG_WIN_COEFS[2], frame: 3, sound: eSounds.EST_EPIC_WIN.name },
    ];

    this.eventMode = 'static';
    this.buttonMode = true;
    this.winAmountCounter = null;
    this.backgroundSound = null;
    this.winDataIndex = null;
    this.startAnimatedValue = 0;
    this.nextAnimatedValue = 0;
    this.idleTime = 3000;
    this.animationStack = [];
    this.playingCoinParticles = [];
    this.animationListener = {
      complete: this.executeAnimation.bind(this)
    };

    this.init();
    this.initParticles();
    this.initWinAnimation();
    this._createMultiplierTextFields();
    this.addListeners();
    this.hide();
  }

  init() {
    const atlasPages = EntryPoint.configData = PIXI.Assets.get(eAnimationTypes.EAT_BIG_WINS).spineAtlas.pages;
    atlasPages.forEach(el => el.baseTexture.alphaMode = PIXI.ALPHA_MODES.PMA);

    this.overlay = this.getChildByName('overlay');
    this.wrapper = this.getChildByName('wrapper');
    this.winAmountContainer = this.wrapper.getChildByName('numbersAnimationContainer');
    this.animationContainer = this.wrapper.getChildByName('animationContainer');

    this.animation = new Animation(eAnimationTypes.EAT_BIG_WINS);

    this.animationContainer.addChild(this.animation);
  }

  addListeners() {
    GlobalDispatcher.add('stateStart:showBigWinState', this.show, this);
    GlobalDispatcher.add(eEventTypes.EET_SEND_MULTIPLIER_TEXT_FIELD, this.showMultiplierWin, this)
    GlobalDispatcher.add('stateStart:skipBigWinState', this.skip, this);
    GlobalDispatcher.add('stateEnd:skipBigWinState', this.complete, this);
    GlobalDispatcher.add('stateEnd:showFreeSpinsMultiplierWinState', this.hide, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    this.on('pointertap', () => {
      StatesManager.goToNextState();
    });
  }

  onResize(data) {
    if (!data.isMobile || !this.winLabel) return;
    this._isPortrait = data.isPortrait;
    this.winLabel.fontSize = this._isPortrait ? 120 : 215;
    if (this.multiplierWinLabel) this.multiplierWinLabel.style.fontSize = this._isPortrait ? 120 : 215;
  }

  show() {
    this.eventMode = 'static'
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
    this.winDataIndex = this.coefs.findIndex(item => item.coef > EntryPoint.GameModel.totalWin / EntryPoint.GameModel.bet);
    if (this.winDataIndex === -1) {
      this.winDataIndex = this.coefs.length - 1;
    } else {
      this.winDataIndex = this.winDataIndex - 1;
    }

    this._playBigWinBackgroundSound()

    this.showParticles();
    this.createAnimationStack(this.winDataIndex);

    this.startAnimatedValue = 0;
    this.nextAnimatedValue = EntryPoint.GameModel.totalWin / this.animationStack.length * 2;
    this.animationContainer.visible = true;

    this.executeAnimation();

    this.visible = true;
  }

  executeAnimation() {
    this.timeout && clearTimeout(this.timeout);
    if (!this.animationStack.length) {
      GlobalDispatcher.dispatch('bigWinContainer:stopState');
      return;
    }

    const animation = this.animationStack.shift();
    let loop = false;

    if (animation.type === 'start') {
      this.animation.animation.state.addListener(this.animationListener);

      this.animateWinning();
      this.updateCoinParticles(animation.coef);
      SoundManager.play(animation.sound, 0.6, false, 'winning');
      this.currentStepSound = animation.sound;
    }

    if (animation.type === 'idle') {
      this.animation.animation.state.removeListener(this.animationListener);

      loop = true;
      this.startAnimatedValue = this.nextAnimatedValue;
      this.nextAnimatedValue = EntryPoint.GameModel.totalWin / this.animationStack.length * 2;
      this.setIdleTimeout();
    }

    this.animation.play(loop, animation.name);
  }

  setIdleTimeout() {
    this.timeout = setTimeout(() => {
      this.animation.stop();
      this.executeAnimation();
    }, this.idleTime)
  }

  skip() {
    this.timeout && clearTimeout(this.timeout);
    this.animation.animation.state.removeListener(this.animationListener);
    this.setIdleTimeout();
    this.animationStack = [];
    this.winAmountCounter.stop();
    this.winLabel.text = EntryPoint.GameModel.totalWin;

    const winData = this.coefs[this.winDataIndex];

    if (this.animation.animationName !== winData.animationName + 'idle') {
      this.animation.play(true, winData.animationName + 'idle');
    }

    if (this.currentStepSound === winData.sound) return;

    SoundManager.stop(this.currentStepSound);
    SoundManager.play(winData.sound, 0.6, false, 'winning');
    this.currentStepSound = winData.sound;
  }

  hide() {
    this.timeout && clearTimeout(this.timeout);
    this.eventMode = 'auto'
    this.stopParticles();
    this.destroyChildrens(this.coinsContainer);
    this.animation.stop();
    this.animationStack = [];
    if (this._multiplierAnimation) {
      this._multiplierAnimation.paused(0);
      this._multiplierAnimation.kill();
      this.winLabel.y = 0;
      this.winLabel.visible = true;
      this.multiplierWinLabel.y = 0;
      this.multiplierWinLabel.visible = false;
      this.overlay.visible = true;
    }
    this.multiplierTextField.visible = false;
    this.visible = false;
  }

  createAnimationStack(index) {
    this.coefs.forEach((el, i) => {
      if (i <= index) {
        const start = { name: el.animationName + 'start', type: 'start', sound: el.sound, coef: el.coef };
        const idle = { name: el.animationName + 'idle', type: 'idle', sound: el.sound, coef: el.coef };

        this.animationStack = [...this.animationStack, start, idle];
      }
    });
  }

  initWinAnimation() {
    this.winLabel = new PIXI.BitmapText('', {
      fontName: 'cc_font',
      fontSize: 215,
    });
    this.winLabel.anchor.set(0.5, 0.5);
    this.winLabel.x = 0;
    this.winLabel.y = 0;
    this.winAmountContainer.addChild(this.winLabel);

    this.winAmountCounter = new WinAmountAnimated();
  }

  animateWinning() {
    this.winAmountCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, EntryPoint.GameModel.currencyInfo.decimals, this.idleTime, this.winLabel);
  }

  initParticles() {
    const texture = PIXI.Texture.from('dotParticle');
    const width = this.displayData.width / 2;
    const height = this.displayData.height / 2;
    const container = new PIXI.Container();
    container.pivot.x = width / 2;
    container.pivot.y = height - height / 2;

    this.coinsContainer = new PIXI.Container();
    this.coinsContainer.pivot.x = width / 2;
    this.coinsContainer.pivot.y = height - height / 2;

    this.smallParticles = new ParticleSystem(container, { texture: texture }, {
      ...particlesConfig.smallParticlesConfig,
      x0max: width,
      y0max: height
    });

    this.bigParticles = new ParticleSystem(container, { texture: texture }, {
      ...particlesConfig.bigParticlesConfig,
      x0max: width,
      y0max: height
    });

    this.animationContainer.addChildAt(this.coinsContainer, 0);
    this.animationContainer.addChildAt(container, 0);
    this.stopParticles();
  }

  updateCoinParticles(coef) {
    const width = this.displayData.width / 2;
    const height = this.displayData.height / 2;

    this.coinParticles = new ParticleSystem(this.coinsContainer, { animationType: 'coins', animationName: 'coin' }, {
      ...particlesConfig.coinParticlesConfig,
      x0min: width / 2,
      x0max: width / 2,
      y0min: height / 2,
      y0max: height / 2,
      count: coef * 4
    });

    this.playingCoinParticles.push(this.coinParticles);
    this.coinParticles.start();
  }

  showParticles() {
    this.smallParticles.start();
    this.bigParticles.start();
  }

  stopParticles() {
    this.bigParticles.stop();
    this.smallParticles.stop();
    this.playingCoinParticles.forEach(el => el.stop());
    this.playingCoinParticles = [];
  }

  destroyChildrens(el) {
    for (let i = 0; i < el.children.length; i++) {
      el.children[i].destroy();
      i--;
    }
  }

  complete() {
    if (!this.visible) return;

    gsap.to(this.backgroundSound, {
      duration: 0.5,
      volume: 0,
      ease: 'none',
      onComplete: () => {
        this.backgroundSound.stop();
        SoundManager.stop(this.currentStepSound);
      }
    });

    this.animation.animation.state.removeListener(this.animationListener);

    this.winAmountCounter.stop();
    SoundManager.play(eSounds.EST_SFX.name, eSounds.EST_SFX.volume, true, eSounds.EST_SFX.group);
    this.hide();
  }

  showMultiplierWin({ params: progressMultiplierTextField }) {
    const offsetX = progressMultiplierTextField.displayData.width / 2 - 32;
    const offsetY = progressMultiplierTextField.displayData.height / 2 + 8;
    this.multiplierCoordinate = this.wrapper.toLocal({ x: offsetX, y: offsetY }, progressMultiplierTextField);

    this.multiplierTextField.text = `×${EntryPoint.GameModel.nextFreeSpinsMultiplier}`

    this.overlay.visible = false;
    this.animationContainer.visible = false;
    this.multiplierTextField.visible = true;
    this.multiplierWinLabel.visible = true;
    this.winLabel.visible = false;
    this.visible = true;
    this._animateMultiplierWin()
    setTimeout(() => GlobalDispatcher.dispatch('bigWinContainer:stopState'), this.idleTime - 1100);
  }

  _animateMultiplierWin() {
    this._multiplierAnimation = gsap.timeline({ paused: true })
    const winLabelStartPosition = -115;
    const multiplierEndScale = EntryPoint.mobile && this._isPortrait ? 0.6 : 1;
    const startValue = 0;

    this.multiplierWinLabel.y = winLabelStartPosition;
    this.multiplierWinLabel.text = startValue;
    this._multiplierAnimation.call(() => this.winAmountCounter.animate(startValue, EntryPoint.GameModel.totalWinWithoutMultiplier, EntryPoint.GameModel.currencyInfo.decimals, 0.2 * 1000, this.multiplierWinLabel), [], 'start')
    this._multiplierAnimation.fromTo(this.multiplierTextField.scale, { x: 0.31, y: 0.31 }, {
      x: multiplierEndScale,
      y: multiplierEndScale,
      duration: 0.2,
      ease: 'power1.in'
    }, 'start')
    this._multiplierAnimation.fromTo(this.multiplierTextField, { x: this.multiplierCoordinate.x }, {
      x: this.winAmountContainer.x,
      duration: 0.6,
      ease: 'power1.out'
    }, 'fly')
    this._multiplierAnimation.fromTo(this.multiplierTextField, { y: this.multiplierCoordinate.y }, {
      y: this.winAmountContainer.y + winLabelStartPosition,
      duration: 0.6,
      ease: 'power4.in'
    }, 'fly')
    this._multiplierAnimation.fromTo(this.multiplierTextField, { alpha: 1 }, {
      alpha: 0,
      duration: 0.1,
      ease: 'power1.in'
    }, 0.7)
    this._multiplierAnimation.call(() => this.multiplierWinLabel.text = EntryPoint.GameModel.totalWin.toFixed(EntryPoint.GameModel.currencyInfo.decimals), [], 'jumpDown')
    this._multiplierAnimation.to(this.multiplierWinLabel, { y: '+=50', duration: 0.2, ease: 'power1.out' }, 'jumpDown')
    this._multiplierAnimation.to(this.multiplierWinLabel, { y: '-=50', duration: 0.4 }, 'jumpUp')

    this._multiplierAnimation.play();
  }

  _createMultiplierTextFields() {
    this.multiplierTextField = new PIXI.Text('', {
      ...violetStrokeStyle,
      fontSize: 300,
      dropShadowDistance: 30,

    });
    this.multiplierTextField.anchor.set(0.5, 0.5);
    this.multiplierTextField.x = this.winAmountContainer.x;
    this.multiplierTextField.y = this.winAmountContainer.y;
    // if (EntryPoint.mobile) {
      this.wrapper.addChildAt(this.multiplierTextField, 0);
    // } else {
    //   this.addChildAt(this.multiplierTextField, 1);
    // }
    this.multiplierTextField.visible = false;

    this.multiplierWinLabel = new PIXI.Text('', {
      ...violetStrokeStyle,
      fontSize: 215,
    });
    this.multiplierWinLabel.anchor.set(0.5, 0.5);
    this.multiplierWinLabel.x = 0;
    this.multiplierWinLabel.y = 0;
    this.winAmountContainer.addChild(this.multiplierWinLabel);
    this.multiplierWinLabel.visible = false;
  }

  _playBigWinBackgroundSound() {
    SoundManager.stop(eSounds.EST_SFX.name);
    this.backgroundSound = SoundManager.play(eSounds.EST_BIG_WIN_BACKGROUND.name, eSounds.EST_BIG_WIN_BACKGROUND.volume, true, eSounds.EST_BIG_WIN_BACKGROUND.group);
  }
}
