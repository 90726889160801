export const createCircleMask = (el) => {
  const deltaHack = 0.00000001;
  const mask = el.mask || new PIXI.Graphics();
  mask.clear();
  mask.beginFill(0x999999);
  mask.arc(0, 0, el.displayData.width / 2, Math.PI * 2 / 100 * 100 - PIXI.DEG_TO_RAD * 90, Math.PI * 1.5 + deltaHack, true);
  mask.lineTo(0, 0);
  mask.endFill();
  mask.x = 0;
  mask.y = 0;
  el.mask = mask;
  el.addChild(mask);
};

export const updateCircleMask = (el, progress) => {
  const deltaHack = 0.00000001;
  const mask = el.mask || new PIXI.Graphics();
  mask.clear();
  mask.beginFill(0x999999);
  mask.arc(0, 0, el.displayData.width / 2, Math.PI * 2 / 100 * progress - PIXI.DEG_TO_RAD * 90, Math.PI * 1.5 + deltaHack, true);
  mask.lineTo(0, 0);
  mask.endFill();
};

export const createRectMask = (el) => {
  const mask = el.mask || new PIXI.Graphics();
  mask.clear();
  mask.beginFill(0x0, 1);
  mask.drawRect(0, 0, el.displayData.width, el.displayData.height);
  mask.endFill();
  el.addChild(mask);
  el.mask = mask;
};

export const updateRectMaskByHeight = (el, progress) => {
  const deltaHack = 1;
  const mask = el.mask || new PIXI.Graphics();
  mask.clear();
  mask.beginFill(0x0, 1);
  mask.drawRect(0, el.displayData.height * (100 - progress) / 100, el.displayData.width, el.displayData.height * progress / 100 + deltaHack);
  mask.endFill();
};

export const updateRectMaskByWidth = (el, progress) => {
  const deltaHack = 1;
  const mask = el.mask || new PIXI.Graphics();
  mask.clear();
  mask.beginFill(0x0, 1);
  mask.drawRect(0, 0, el.displayData.width * progress / 100 + deltaHack, el.displayData.height);
  mask.endFill();
};
