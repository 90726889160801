import ControllerTip from './ControllerTip';

export default class ControllerBombTip extends ControllerTip {
  constructor(data, displayData) {
    super(data, displayData);
    this.text = this.getChildByName('ui_tnt_tip');
  }
  show(canUseBombNow = true, bought = false) {
    const localizationKey =  canUseBombNow ? 'ui_tnt_tip' : bought ? 'ui_bought_tnt_tip':  'ui_have_tnt_tip';
    this.text.text = OPWrapperService.localizations.getLocalizedText(localizationKey)
    this.text.fitSize();
    this.text.correctPosition('center', 'center');
    super.show();
  }
}
