import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import GameModel from '../models/GameModel';

export default class BuyBonusState extends BaseState {
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_DATA_UPDATED, this.complete, this);
  }

  start() {
    super.start();
    OPWrapperService.ControllerStatistic.bet = GameModel.bonusPurchaseData.price;
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    GlobalDispatcher.dispatch(eEventTypes.EET_BUY_BONUS);
  }

  complete({ params }) {
    if (params === 'buy') {
      GameModel.bonusPurchaseData = null;
      super.complete();
    }
  }
}
