import FlashLib from 'flashlib_onlyplay';

export class AbstractSceneController extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.active = false;
    this.init();
    this.addListeners();
  }

  addListeners() {
  }

  init() {
  }

  start() {
    this.active = true;
  }

  sceneDidAppear() {
  }

  complete() {
    this.active = false;
  }
}
