import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';

export default class WaitUserInteractionState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();
  }

  start() {
    EntryPoint.GameModel.isFreeSpinsInActiveState = true;
    super.start();
  }
}
