import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import gameConfig from '../../configs/gameConfig';

export default class ControllerBackground extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    if (!EntryPoint.mobile) {
      this.goToFrame(3);
      this._initCharacters();
    }
  }

  correctCharactersPosition(data) {
    if (data.stageX >= 0) return this._charactersAnimation.x = 0;
    this._charactersAnimation.x = - data.stageX / data.scale;
  }

  _initCharacters() {
    this._charactersAnimationPlace = this.getChildByName('charactersPlace');
    this._charactersAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_CHARACTERS);
    this._charactersAnimation.state.setAnimation(0, 'idle', true);
    this._charactersAnimationPlace.addChild(this._charactersAnimation);

    this._addCharactersListeners();
  }

  _addCharactersListeners() {
    GlobalDispatcher.add(eEventTypes.EET_BOMB_ACTION, this._charactersAction1.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_COMBINATION_PLAYING, this._charactersAction2.bind(this));
  }

  _charactersAction1() {
    this._charactersAnimation.state.setAnimation(0, 'action_1', false);
    this._charactersAnimation.state.addAnimation(0, 'idle', true, 0);
  }

  _charactersAction2({ params }) {
    if (EntryPoint.GameModel.combinations.length < gameConfig.COMBINATIONS_COUNT_BEFORE_EXPRESSION ||
      !EntryPoint.GameModel.isLastCombination(params)) return;

    this._charactersAnimation.state.setAnimation(0, 'action_2', false);
    this._charactersAnimation.state.addAnimation(0, 'idle', true, 0);
  }
}
