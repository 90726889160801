import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';

export default class ColorBombActionState extends BaseState {
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_COLOR_BOMB_ACTION_COMPLETE, this.onComplete, this)
  }

  onComplete() {
    if (this.active) {
      this.complete();
    }
  }
}
