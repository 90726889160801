import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';

export default class ControllerTip extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.visible = false;
    this.alpha = 0;
  }

  init() {
    this._initAnimation();
  }

  show() {
    this.visible = true;
    this._startAnimation();

    this.hideTween && this.hideTween.kill();
    this.showTween = gsap.to(this, {
      alpha: 1,
      duration: 1,
    }).play();
  }

  hide() {
    this.showTween && this.showTween.kill();
    this.hideTween = gsap.to(this, {
      alpha: 0,
      duration: 1,
      onComplete: this._onHideComplete.bind(this),
    }).play();
  }

  _onHideComplete() {
    this.x = this.displayData.x;
    this.y = this.displayData.y;
    this.visible = false;
    this._pauseAnimation();
  }

  _startAnimation() {
    this.timeline.play();
  }

  _pauseAnimation() {
    this.timeline.pause();
  }

  _initAnimation() {
    this.timeline = gsap.timeline({ repeat: -1, paused: true });

    this.timeline.to(this, {
      y: '-=10',
      duration: 1,
      ease: 'power1',
    }).to(this, {
      y: '+=10',
      duration: 1,
      ease: 'back.in(1.7)'
    });
  }

  destroy(opts) {
    gsap.killTweensOf(this);
    super.destroy(opts);
  }
}
