import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import EntryPoint from 'Engine/EntryPoint';
import eStateTypes from '../../enums/eStateTypes';

export default class UiAdapterExtended extends UiAdapter {
  initStateHandlers() {
    super.initStateHandlers()
    this.extraStateHandlers = {
      [eStateTypes.EBST_IDLE]: () => {
        this.ControllerUi.showSpinButton();
        this.ControllerUi.setWin(EntryPoint.GameModel.totalWin);
      },
      [eStateTypes.EBST_REQUEST_ROLLING]: () => {
        this.ControllerUi.showSpinButton();
        this.ControllerUi.toggleStopButton(false);
      },
      [eStateTypes.EBST_MINIMAL_ROLLING]: () => {
        this.ControllerUi.toggleStopButton(false);
      },
      [eStateTypes.EST_FREE_SPINS_ADD]: () => {
        this.ControllerUi.showSpinButton();
        this.hideUi();
      },
      [eStateTypes.EBST_FREE_SPINS_IN]: () => {
        this.ControllerUi.showSpinButton();
      },
      [eStateTypes.EBST_FREE_SPINS_OUT]: () => {
        this.hideUi();
        this.ControllerUi.showSpinButton();
      },
      default: () => {
        this.ControllerUi.showSpinButton();
      }
    };
  }
}
