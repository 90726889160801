import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import eEventTypes from '../../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';

export default class ControllerBombTimer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._active = false;
    this.visible = false;
    this.alpha = 0;
    this._timerDuration = EntryPoint.GameModel.bombFeatureTimeout;
    this.init();

    this._tick = this._tick.bind(this);
  }

  init() {
    this.timeLabel = this.getChildByName('timeLabel');
  }

  start() {
    if (this._active) return;

    this.visible = true;
    gsap.to(this, { alpha: 1, duration: 1 }).play();
    this._active = true;
    this._startTime = EntryPoint.GameModel.spinEndTimestamp;
    requestAnimationFrame(this._tick);
  }

  stop() {
    if (!this._active) return;

    this._active = false;
    gsap.to(this, { alpha: 0, duration: 1, onComplete: this._onHideComplete.bind(this) }).play();
  }

  _onHideComplete() {
    this.visible = false;
  }

  _timeEnd() {
    this.emit(eEventTypes.EET_BOMB_TIME_END);
  }

  _tick() {
    if (!this._active) return;
    const now = Date.now();
    let totalSeconds = this._timerDuration - Math.floor((now - this._startTime) / 1000);
    if (totalSeconds <= -1) return this._timeEnd();

    let minutes = parseInt((totalSeconds / 60).toString());
    let seconds = totalSeconds % 60;

    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;
    this.timeLabel.text = `${minutes} : ${seconds}`;
    requestAnimationFrame(this._tick);
  }
}
