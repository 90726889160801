import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';

export default class ControllerPaytableContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addListeners();
    this.hide();
  }

  init() {
    this.container = this.getChildByName('container');
    this.content = this.container.getChildByName('content');
    this.controls = this.container.getChildByName('controls');
    this.pagesCount = this.content.layersData[0].frameCount;

    let range = [];
    for (let i = 1; i <= this.pagesCount; i++) {
      range.push(i);
    }

    this.controls.setRange(range);
    this.content.goToFrame(this.controls.currentPage);
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE_CLICK, this.show.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE_BACK_CLICK, this.hide.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_SPIN_CLICK, this.hide.bind(this));
  }

  changeFrame(value) {
    const page = this.controls.currentPage;
    this.container.goToFrame(value);
    this.init();
    this.controls.setPage(page);
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
