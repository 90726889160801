import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import eEventTypes from '../../enums/eEventTypes';
import { eSounds } from '../../enums/eSounds';

export default class ControllerPaytableControls extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addListeners();

    this.eventMode = 'static'
    this.range = null;
    this.currentPage = 1;
  }

  init() {
    this.btnLeft = this.getChildByName('btnLeft');
    this.btnRight = this.getChildByName('btnRight');
    this.btnBack = this.getChildByName('btnBack');
    makeHitArea(this.btnLeft);
    makeHitArea(this.btnRight);
    makeHitArea(this.btnBack);
    this.btnBack.enabled = true;
  }

  addListeners() {
    this.btnLeft.on('pointertap', this.onLeftClick.bind(this));
    this.btnRight.on('pointertap', this.onRightClick.bind(this));
    this.btnBack.on('pointertap', this.onBackClick.bind(this));
  }

  checkButtonsEnabled() {
    this.btnLeft.enabled = this.range.indexOf(this.currentPage) > 0;
    this.btnRight.enabled = this.range.indexOf(this.currentPage) < this.range.length - 1;
  }

  onBackClick() {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name);
    GlobalDispatcher.dispatch(eEventTypes.EET_PAYTABLE_BACK_CLICK);
    GlobalDispatcher.dispatch(eEventTypes.EBET_PAYTABLE__BACK_CLICK);
    this.reset();
  }

  onLeftClick() {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name);
    this.currentPage--;
    GlobalDispatcher.dispatch(eEventTypes.EET_PAYTABLE_CHANGE_PAGE, this.currentPage);
    this.checkButtonsEnabled();
  }

  onRightClick() {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name);
    this.currentPage++;
    GlobalDispatcher.dispatch(eEventTypes.EET_PAYTABLE_CHANGE_PAGE, this.currentPage);
    this.checkButtonsEnabled();
  }

  setRange(array) {
    this.range = array;
    this.checkButtonsEnabled();
  }

  reset() {
    this.currentPage = 1;
    this.checkButtonsEnabled();
  }

  setPage(page) {
    if (!this.range.includes(page)) return;
    this.currentPage = page;
    GlobalDispatcher.dispatch(eEventTypes.EET_PAYTABLE_CHANGE_PAGE, this.currentPage);
    this.checkButtonsEnabled();
  }
}
