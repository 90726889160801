import FlashLib from 'flashlib_onlyplay';
import gameConfig from '../../configs/gameConfig';
import eLibraryItems from '../../enums/eLibraryItems';

export default class Pool {

  push(symbol) {
    symbol.visible = false;
    symbol.resetAnimation();
    this.symbols.push(symbol);
  }

  pull(id) {
    const symbol = this.symbols.shift() || this._createSymbol();
    symbol.visible = true;
    symbol.changeSymbol(id);
    return symbol;
  }

  _createSymbol() {
    return FlashLib.createItemFromLibrary(eLibraryItems.ELI_SYMBOL, 'GameFlashLib');
  }

  get symbols() {
    if (this._symbols) return this._symbols;

    this._symbols = [];
    const symbolsCount = gameConfig.COLUMNS_COUNT * gameConfig.LINES_COUNT * 2;
    for (let i = 0; i < symbolsCount; i++) {
      const symbol = this._createSymbol();
      this._symbols.push(symbol);
    }

    return this._symbols;
  }
}
