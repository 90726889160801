export default {
  RTP: '96.11%',
  GAME_WIDTH: 2340,
  GAME_HEIGHT: 1080,
  MINIMAL_SPIN_TIME: 1000,
  COLUMNS_COUNT: 8,
  LINES_COUNT: 8,
  SYMBOLS: {
    width: 110,
    height: 107,
    topOffset: 3,
    sideOffset: 0,
  },
  WILD_ID: 1,
  BIG_WIN_COEFS: [10, 20, 30],
  COMBINATIONS_COUNT_BEFORE_EXPRESSION: 2,
}
