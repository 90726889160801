import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';

export default class ControllerSymbol extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._animationRelations = {
      1: 'crystal_wild',
      2: 'red',
      3: 'purple',
      4: 'pink',
      5: 'star',
      6: 'blue',
      7: 'yellow',
      8: 'green',
    };

    this.init();

    this._onCompleteAnimation = this._onCompleteAnimation.bind(this);
    this.playWinAnimation = this.playWinAnimation.bind(this);
  }

  init() {
    this._animationPlace = this.getChildByName('animationPlace');
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_SYMBOLS);
    this._animationPlace.addChild(this.animation);
  }

  changeSymbol(id) {
    this._id = id;
    const track = this.playIdleAnimation();
    this.animation.state.update(track.animation.duration / this.animation.state.timeScale);
  }

  playWinAnimation() {
    this.animation.state.clearListeners();
    this.animation.state.setAnimation(0, this._animationRelations[this._id], false);
    this.animation.state.timeScale = 1;
    this.animation.state.addListener({ complete: this._onCompleteAnimation });
  }

  playWild() {
    this.animation.state.setAnimation(0, `${this._animationRelations[this._id]}_start`, false);
    this.animation.state.timeScale = 1;
    this.animation.state.addListener({ complete: this._onCompleteAnimation });
  }

  _onCompleteAnimation() {
    this.animation.state.clearListeners();
    this.emit('winAnimationComplete');
    this.removeAllListeners();
  }

  playIdleAnimation() {
    this.animation.state.timeScale = 0.5;
    return this.animation.state.setAnimation(0, `${this._animationRelations[this._id]}_idle`, false);
  }

  resetAnimation() {
    this.animation.lastTime = null;
    this.animation.state.clearTrack(0);
    this.animation.skeleton.setToSetupPose();
  }

  get id() {
    return this._id;
  }
}
