export default {
  EST_CRYSTAL: 1,
  EST_RED: 2,
  EST_VIOLET: 3,
  EST_PINK: 4,
  EST_ORANGE: 5,
  EST_BLUE: 6,
  EST_YELLOW: 7,
  EST_GREEN: 8,
}
