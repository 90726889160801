let config;

export const getBonusPurchaseCardsConfig = () => {
  if (!config) {
    config = {
      EBPT_TNT_BOOSTER: {
        label: window.OPWrapperService.localizations.getLocalizedText('purchase_card_label_tnt_booster'),
        type: 'booster',
        animations: {
          idle: 'idle_tnt_card',
          action: 'buy_tnt_card',
        }
      },
      EBPT_FREE_SPINS: {
        label: window.OPWrapperService.localizations.getLocalizedText('purchase_card_label_10_freespins'),
        type: 'freespins',
        animations: {
          idle: 'idle_10_free_spins_colb_card3',
          action: 'buy_+10_free_spins_colb_card3',
        }
      }
    }
  }
  return config;
}
