import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class ShowFreeSpinsMultiplierState extends BaseState {
  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('bigWinContainer:stopState', this.complete, this);
  }

  complete() {
    if (this.active) {
      super.complete();
    }
  }

  onEnd() {
    super.onEnd();
  }
}
