import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import { initWrapperUi } from '../controllers/ui/initWrapperUi';
import EntryPoint from 'Engine/EntryPoint';
export default class InitState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('game:gameStarted', this.onGameStarted, this);
    }

    onGameStarted() {
        if (!EntryPoint.GameSettings.introScreen) initWrapperUi();
        if (EntryPoint.GameModel.isFreeSpinsMode) window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME);
        this.complete();
    }
}
