import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import eEventTypes from '../enums/eEventTypes';

export default class StopRollingState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add(eEventTypes.EET_FIELD_STOPPED, this.onFieldStopped, this);
    }

    onFieldStopped() {
        if (this.active) {
            this.complete();
            // setTimeout(() => this.complete(), 2000);
        }
    }
}
