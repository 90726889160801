export const freeSpinsWindowStyle = {
  dropShadow: true,
  dropShadowAngle: 1.6,
  dropShadowColor: '#ac090b',
  dropShadowDistance: 14,
  fill: [
    '#fcff00',
    '#fef600',
    '#feff00',
    '#feff92',
    '#fffd8f',
    '#fafe25',
    '#ffb400'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.6,
    0.6,
    0.6,
    0.7,
    0.7
  ],
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffedee',
  strokeThickness: 6
};

export const goldStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowColor: '#ac090a',
  dropShadowDistance: 12,
  fill: [
    '#ffff6e',
    '#ffff9e',
    '#ffb209',
    '#ff780a'
  ],
  fillGradientStops: [
    0.2,
    0.4,
    0.6,
    0.7
  ],
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffde05',
  strokeThickness: 3
};

export const yellowStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowColor: '#51020a',
  dropShadowDistance: 12,
  fill: [
    '#ff6d00',
    'yellow',
    'white',
    'yellow',
    '#ff6d00'
  ],
  fillGradientStops: [
    0.2,
    0.4,
    0.5,
    0.6,
    0.9
  ],
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#d6040d',
  strokeThickness: 6
};

export const greenStyle = {
  dropShadow: true,
  dropShadowAngle: 2,
  dropShadowColor: 'white',
  dropShadowDistance: 2,
  fill: '#9bf502',
  fontFamily: 'Fairy Muffin Round Pop',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#316e15',
  strokeThickness: 14
};

export const blueStrokeStyle = {
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#201180',
  strokeThickness: 6
};

export const violetStrokeStyle = {
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#590489',
  strokeThickness: 24,
  fill: 'white'
};

export const whiteYellowOrange = {
  fill: [
    'white',
    'yellow',
    '#ffa200'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.7
  ]
};

export const shadowText = {
  dropShadow: true,
  dropShadowAlpha: 0.8,
  dropShadowBlur: 4
};
