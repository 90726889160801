import FlashLib from 'flashlib_onlyplay';
import eAnimationTypes from '../../enums/eAnimationTypes';
import animationCreator from 'Engine/animations/animationCreator';
import GlobalDispatcher from '../../libs/game-engine/src/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import { createRectMask, updateRectMaskByHeight, updateRectMaskByWidth } from '../../utils/masks';
import eProgressBarMods from '../../enums/eProgressBarMods';
import EntryPoint from 'Engine/EntryPoint';
import { yellowStyle } from '../../textStyles/styles';
import SoundManager from '../../libs/game-engine/src/soundManager/SoundManager';
import { eSounds } from '../../enums/eSounds';

export default class ControllerProgress extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._maskUpdateFunctions = {
      1: EntryPoint.mobile ? updateRectMaskByWidth : updateRectMaskByHeight,
      2: updateRectMaskByHeight,
    };

    this._frameWillChange = false;
    this._maskUpdateStartTime = 0;
    this._currentMode = eProgressBarMods.EPBM_REGULAR;
    this._maskUpdateAnimationTime = 1000;
    this._maskResetAnimationTime = 400;
    this._previousProgress = 0;
    this._currentProgress = 0;
    this.colorBombs = [];

    this.init();
    this.addListeners();
  }

  init() {
    this.progressBar = this.getChildByName('progressBar');
    this.flaskAnimationPlace = this.getChildByName('animationFlask');
    this.bubblesAnimationPlace = this.progressBar.getChildByName('animationBubbles');
    this.multiplier = this.getChildByName('multiplier');
    this.tip = this.getChildByName('tip');

    this.multiplier.style = { ...this.multiplier.style, ...yellowStyle };

    this.colorBombs = [];
    for (let i = 0; i < 3; i++) {
      this.colorBombs.push(this.getChildByName(`color_bomb_${i}`));
    }

    this._createMask();
    this._initAnimations();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_COLOR_BOMB_STATE_START, this._startColorBomb, this);
    GlobalDispatcher.add(eEventTypes.EET_COMBINATION_PLAYING, this._onCombinationPlaying, this);
    GlobalDispatcher.add(eEventTypes.EET_ROLLING_STATE_START, this._reset, this);
    GlobalDispatcher.add(eEventTypes.EET_WAIT_USER_INTERACTION_START, this._switch, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_ADD_END, this._switch, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_IN_START, this._switch, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_OUT_END, this._switch, this);
    GlobalDispatcher.add('stateStart:showFreeSpinsMultiplierWinState', this._sendMultiplierTextField, this);
  }

  changeFrame(value) {
    const colorBombProps = this.colorBombs.map(bomb => ({ used: bomb.used, restart: bomb.restart }));
    this._frameWillChange = true;
    this._destroyAnimations();
    this.goToFrame(value);
    this.init();
    this._currentMode = '';
    const progress = this._currentProgress;
    this._switch();
    for (let i = 0; i < this.colorBombs.length; i++) {
      this.colorBombs[i].changeFrame(value);
      this.colorBombs[i].used = colorBombProps[i].used;
      this.colorBombs[i].restart = colorBombProps[i].restart;
    }
    this._currentProgress = progress;
    this._maskUpdateFunctions[value](this.progressBar, this._currentProgress);
    this.active = true;
    this._handleProgressFeatures(this._currentProgress);
  };

  _onCombinationPlaying({ params }) {
    this.active = true;
    const progress = params.progress_next;
    this._updateProgress(progress);
  }

  _reset() {
    this.active = false;
    this._frameWillChange = false;
    this._updateProgress(0);

    if (this.currentMode === eProgressBarMods.EPBM_REGULAR) {
      this._emptyFreeSpinsFlask();
      this.colorBombs.forEach(bomb => bomb.active = false);
    }

    if (this.currentMode === eProgressBarMods.EPBM_FREESPINS) {
      this._emptyMultiplierFlask();
      this.multiplier.text = `×${EntryPoint.GameModel.nextAvailableFreeSpinsMultiplier}`;
    }
  }

  _switch() {
    this.mode = EntryPoint.GameModel.isFreeSpinsInActiveState ? eProgressBarMods.EPBM_FREESPINS : eProgressBarMods.EPBM_REGULAR;
  }

  _updateProgress(progress) {
    this._maskUpdateStartTime = Date.now();
    this._previousProgress = this._currentProgress;
    this._currentProgress = progress;

    if (this._previousProgress < this._currentProgress) SoundManager.play(eSounds.EST_INCREASE_PROGRESS.name, eSounds.EST_INCREASE_PROGRESS.volume);

    this.currentLevelLineAnimation.state.setAnimation(0, 'level_step', true);
    requestAnimationFrame(this._update.bind(this));
  }

  _startColorBomb() {
    const availableBomb = this.colorBombs.find(bomb => bomb.active && !bomb.used);
    if (!availableBomb) return console.warn('Can\'t find available bomb');
    availableBomb.start();
  }

  _initAnimations() {
    this.flaskAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_PROGRESS);
    this._emptyFreeSpinsFlask();
    this.flaskAnimationPlace.addChild(this.flaskAnimation);
    this.flaskAnimation.x = this.flaskAnimation.y = 0;

    this.bubblesAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_PROGRESS);
    this.bubblesAnimation.state.setAnimation(0, 'bubbles', true);
    this.bubblesAnimationPlace.addChild(this.bubblesAnimation);
    this.bubblesAnimation.x = this.bubblesAnimation.y = 0;

    this.currentLevelLineAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_PROGRESS);
    this.currentLevelLineAnimation.state.setAnimation(0, 'level_idle', true);
    this.progressBar.addChild(this.currentLevelLineAnimation);
    this._updateCurrentLevelLinePosition();

    if (EntryPoint.mobile && this.currentFrameIndex === 1) {
      this.bubblesAnimation.rotation = PIXI.DEG_TO_RAD * 90;
      this.currentLevelLineAnimation.rotation = PIXI.DEG_TO_RAD * 90;
    }
  }

  _destroyAnimations() {
    this.flaskAnimation.destroy({ children: true });
    this.bubblesAnimation.destroy({ children: true });
    this.currentLevelLineAnimation.destroy({ children: true });
  }

  _update() {
    if (this._frameWillChange) return;
    const now = Date.now();
    const animationTime = this.active ? this._maskUpdateAnimationTime : this._maskResetAnimationTime;
    const diff = now - this._maskUpdateStartTime;

    if (diff >= animationTime) {
      this._maskUpdateFunctions[this.currentFrameIndex](this.progressBar, this._currentProgress);
      this.currentLevelLineAnimation.state.setAnimation(0, 'level_idle', true);
      this._handleProgressFeatures(this._currentProgress);
      this._updateCurrentLevelLinePosition();
      return;
    }

    const progress = this._previousProgress - diff * (this._previousProgress - this._currentProgress) / animationTime;
    this._handleProgressFeatures(progress);
    this._maskUpdateFunctions[this.currentFrameIndex](this.progressBar, progress);
    this._updateCurrentLevelLinePosition();

    requestAnimationFrame(this._update.bind(this));
  }

  _handleProgressFeatures(progress) {
    if (!this.active) return;

    switch (this.currentMode) {
      case eProgressBarMods.EPBM_REGULAR:
        this._handleRegularProgressFeatures(progress);
        break;
      case eProgressBarMods.EPBM_FREESPINS:
        this._handleFreeSpinsProgressFeatures(progress);
        break;
    }
  }

  _handleRegularProgressFeatures(progress) {
    if (progress >= 25) this.colorBombs[0].tryToActivate();
    if (progress >= 50) this.colorBombs[1].tryToActivate();
    if (progress >= 75) this.colorBombs[2].tryToActivate();
    if (this._previousProgress !== 100 && progress === 100) this._playWinFreeSpinsAnimation();
  };

  _handleFreeSpinsProgressFeatures(progress) {
    if (this._previousProgress !== 100 && progress === 100) this._nextMultiplier();
  };

  _playWinFreeSpinsAnimation() {
    this.flaskAnimation.autoUpdate = true;
    this.flaskAnimation.state.setAnimation(0, 'free_spins_start', false);
    this.flaskAnimation.state.addAnimation(0, 'free_spins_idle', true, 0);
  };

  _nextMultiplier() {
    this.flaskAnimation.autoUpdate = true;
    this.flaskAnimation.state.setAnimation(0, 'top_flask_start', false);
    this.flaskAnimation.state.addAnimation(0, 'top_flask_idle', true, 0);
  }

  _emptyFreeSpinsFlask() {
    this.flaskAnimation.state.setAnimation(0, 'free_spins_start', true);
    this.flaskAnimation.autoUpdate = false;
    this.flaskAnimation.update(0);
  }

  _emptyMultiplierFlask() {
    this.flaskAnimation.state.setAnimation(0, 'top_flask_start', true);
    this.flaskAnimation.autoUpdate = false;
    this.flaskAnimation.update(0);
  }

  _updateCurrentLevelLinePosition() {
    if (EntryPoint.mobile && this.currentFrameIndex === 1) {
      this.currentLevelLineAnimation.y = this.progressBar.displayData.height / 2;
      this.currentLevelLineAnimation.x = this.progressBar.mask.width;
    } else {
      this.currentLevelLineAnimation.x = this.progressBar.displayData.width / 2;
      this.currentLevelLineAnimation.y = this.progressBar.displayData.height - this.progressBar.mask.height;
    }
  }

  _createMask() {
    createRectMask(this.progressBar);
    this._maskUpdateFunctions[this.currentFrameIndex](this.progressBar, this._currentProgress);
  }

  _sendMultiplierTextField() {
    GlobalDispatcher.dispatch( eEventTypes.EET_SEND_MULTIPLIER_TEXT_FIELD, this.multiplier, this)
  }

  set mode(value) {
    if (!Object.values(eProgressBarMods).includes(value)) return;
    if (this.currentMode === value) return;

    this._currentMode = value;
    switch (this._currentMode) {
      case eProgressBarMods.EPBM_REGULAR:
        this.tip.hide();
        this.progressBar.goToFrame(1);
        this.multiplier.text = '';
        this.multiplier.visible = false;
        this.colorBombs.forEach(bomb => bomb.show());
        break;
      case eProgressBarMods.EPBM_FREESPINS:
        EntryPoint.GameModel.showFreeSpinsTip && this.tip.show();
        this.progressBar.goToFrame(2);
        this.multiplier.visible = true;
        this.multiplier.text = `×${EntryPoint.GameModel.nextAvailableFreeSpinsMultiplier}`;
        this.colorBombs.forEach(bomb => bomb.hide());
        break;
      default:
        console.warn('Unhandled progress bar mod: ', value);
    }

    this._reset();
  }

  set active(value) {
    this._active = value;
  }

  get currentMode() {
    return this._currentMode
  }

  get active() {
    return this._active;
  }
}
